import {
  DayScheduleLight,
  DayScheduleRequest,
  DayScheduleRequestBase,
  Facility,
  FacilityLight,
  Lead,
} from '@carers/models/RequestAndMission';
import {
  DayScheduleLightDTO,
  DayScheduleRequestBaseDTO,
  DayScheduleRequestDTO,
  FacilityDTO,
  FacilityLightDTO,
  LeadDTO,
} from '@carers/models/RequestAndMissionDTO';

const mapConflictingDaySchedulesDTOToConflictingDaySchedules = (
  dto: DayScheduleRequestBaseDTO[],
): DayScheduleRequestBase[] => {
  return dto.map((cdsDto) => {
    return {
      id: cdsDto.id,
      date: cdsDto.date,
      startTime: cdsDto.startTime,
      endTime: cdsDto.endTime,
      rythm: cdsDto.rythm,
      facilityName: cdsDto.facilityName,
    };
  });
};

const mapDayScheduleRequestDTOToDayScheduleRequest = (
  dto: DayScheduleRequestDTO[],
): DayScheduleRequest[] => {
  return dto.map((sclDto) => {
    return {
      id: sclDto.id,
      date: sclDto.date,
      startTime: sclDto.startTime,
      endTime: sclDto.endTime,
      rythm: sclDto.rythm,
      breakDuration: sclDto.breakDuration,
      grossRemuneration: sclDto.grossRemuneration,
      netRemuneration: sclDto.netRemuneration,
      conflictingDaySchedules: mapConflictingDaySchedulesDTOToConflictingDaySchedules(
        sclDto.conflictingDaySchedules,
      ),
    };
  });
};

const mapDayScheduleLightDTOToDayScheduleLight = (
  dto: DayScheduleLightDTO[],
): DayScheduleLight[] => {
  return dto.map((sclDto) => {
    return {
      id: sclDto.id,
      date: sclDto.date,
      startTime: sclDto.startTime,
      endTime: sclDto.endTime,
      rythm: sclDto.rythm,
      breakDuration: sclDto.breakDuration,
    };
  });
};

const mapFacilityLightDTOToFacilityLight = (dto: FacilityLightDTO): FacilityLight => {
  return {
    name: dto.name,
    city: dto.city,
  };
};

const mapFacilityDTOToFacility = (dto: FacilityDTO): Facility => {
  return {
    name: dto.name,
    city: dto.city,
    address: dto.address,
    postalCode: dto.postalCode,
    googleMapsUrl: dto.googleMapsUrl,
    switchboardPhone: dto.switchboardPhone,
    cardUrl: dto.cardUrl,
  };
};

const mapLeadDTOToLead = (dto: LeadDTO): Lead => {
  return {
    firstName: dto.firstName,
    email: dto.email,
    phone: dto.phone,
  };
};

export {
  mapFacilityLightDTOToFacilityLight,
  mapFacilityDTOToFacility,
  mapLeadDTOToLead,
  mapDayScheduleLightDTOToDayScheduleLight,
  mapDayScheduleRequestDTOToDayScheduleRequest,
};
