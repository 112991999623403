import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { RefObject, useId, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import CardMission from '@carers/components/CardMission/CardMission';
import CardsMissionsSkeleton from '@carers/components/CardMission/CardsMissionsSkeleton';
import GridCards from '@carers/components/GridCards/GridCards';
import HeaderIcon from '@carers/components/HeaderIcon/HeaderIcon';
import ListFilterButton from '@carers/components/ListFilterButton/ListFilterButton';
import { Modal } from '@carers/components/Modal';
import PageContentWithSidePanel from '@carers/components/PageContentWithSidePanel/PageContentWithSidePanel';
import useGetAppConstants from '@carers/hooks/useGetAppConstants';
import { useSearchParamFilter } from '@carers/hooks/useSearchParamFilter';
import { ANALYTICS_LOG_EVENTS } from '@carers/utils/Analytics.ts';
import useFetchRequests from '@carers/views/Requests/hooks/useFetchRequests';
import { RequestsFilters } from '@carers/views/Requests/models/RequestsFilters';
import RequestsFilter from '@carers/views/Requests/RequestsList/components/RequestsFilter/RequestsFilter';
import PhoneIcon from '@shared/assets/icons/local_phone.svg';
import NotFoundImage from '@shared/assets/images/status/not-found.svg';
import BlankSlate from '@shared/components/BlankSlate/BlankSlate';
import BlankSlateError from '@shared/components/BlankSlate/BlankSlateError';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';
import { GenericAxiosResponseError } from '@shared/HttpInterceptors/GenericAxiosResponseError';
import { HTTPErrorCode } from '@shared/HttpInterceptors/HTTP_ERROR_CODES';
import { logEvent } from '@shared/utils/Analytics';

const NO_ACCESS_REQUESTS = [
  HTTPErrorCode.UNSUBSCRIBED_CARER,
  HTTPErrorCode.CARER_WITH_JOB_NOT_COVERED,
  HTTPErrorCode.CARER_WITH_PENDING_CANCELLATION_JUSTIFICATION,
];

const hasNoAccessRequestError = (error: GenericAxiosResponseError) =>
  error.response?.data.error?.code && NO_ACCESS_REQUESTS.includes(error.response.data.error.code);

type FiltersProps = {
  shouldDisplayFilters: boolean;
  isFetchRequestsLoading: boolean;
  filters: RequestsFilters;
  filtersCount: number;
  handleSubmitFilter: (filters: RequestsFilters) => void;
  filterModalRef: RefObject<HTMLIonModalElement>;
};

const Filters = ({
  shouldDisplayFilters,
  isFetchRequestsLoading,
  filters,
  filtersCount,
  filterModalRef,
  handleSubmitFilter,
}: FiltersProps) => {
  const filterModalId = useId();

  if (!shouldDisplayFilters) {
    return null;
  }

  return (
    <>
      <ListFilterButton
        isDisabled={isFetchRequestsLoading}
        buttonId={filterModalId}
        filtersCount={filtersCount}
      />
      <Modal
        ref={filterModalRef}
        trigger={filterModalId}
        breakpoints={[0, 1]}
        initialBreakpoint={1}
        aria-labelledby="filter-modal-title"
      >
        <RequestsFilter onSubmit={handleSubmitFilter} appliedFilters={filters} />
      </Modal>
    </>
  );
};

const Requests = () => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.requests.list' });
  const isScreenXL = useIsScreenXL();

  const pageIonContent = useRef<HTMLIonContentElement>(null);

  const filterModal = useRef<HTMLIonModalElement>(null);

  const { filters, filtersCount, hasFilters, applyFilters } =
    useSearchParamFilter<RequestsFilters>();

  const {
    fetchNextPage,
    refetch,
    hasNextPage,
    isLoading,
    isError,
    isRefetchError,
    error,
    data: requestsData,
  } = useFetchRequests(filters);

  const { data: appConstants } = useGetAppConstants();

  const hasNoData = !requestsData || requestsData.pages.length === 0;
  const shouldShowBlobs = !hasNoData || isLoading;

  const shouldDisplayFilters = !isScreenXL && !isError && (!hasNoData || hasFilters);

  const handleSubmitFilter = async (newFilters: RequestsFilters) => {
    await filterModal.current?.dismiss();
    applyFilters(newFilters);
    await pageIonContent.current?.scrollToTop();
  };

  const getRequestsContent = () => {
    if (hasNoData) {
      if (isError) {
        if (hasNoAccessRequestError(error)) {
          return (
            <BlankSlateError error={error}>
              <IonButton
                fill="clear"
                className="ion-text-wrap"
                href={`tel:${appConstants?.opsLead.phone}`}
              >
                <IonIcon aria-hidden="true" slot="start" icon={PhoneIcon} />
                {t('actions.contact')}
              </IonButton>
            </BlankSlateError>
          );
        }

        return <BlankSlateError error={error} action={refetch} />;
      }

      if (!isLoading) {
        if (hasFilters) {
          void logEvent(ANALYTICS_LOG_EVENTS.EMPTY_REQUESTS_LIST_WITH_FILTERS);

          return (
            <BlankSlate message={t('messages.errors.noDataWithFilters')} image={NotFoundImage} />
          );
        }

        void logEvent(ANALYTICS_LOG_EVENTS.EMPTY_REQUESTS_LIST);

        return <BlankSlate message={t('messages.errors.noData')} image={NotFoundImage} />;
      }
    }

    return (
      <>
        <GridCards>
          {isLoading ? (
            <CardsMissionsSkeleton />
          ) : (
            <>
              {requestsData!.pages.map((mission) => (
                <CardMission mission={mission} key={mission.id} />
              ))}
              {isRefetchError && (
                <IonText
                  color="danger"
                  className="ion-padding"
                  onClick={async () => fetchNextPage()}
                >
                  {t('messages.errors.infiniteScroll')}
                </IonText>
              )}
            </>
          )}
        </GridCards>
        <IonInfiniteScroll
          disabled={!hasNextPage}
          onIonInfinite={async (event) => {
            await fetchNextPage();
            await event.target.complete();
          }}
        >
          <IonInfiniteScrollContent />
        </IonInfiniteScroll>
      </>
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HeaderIcon slot="start" />
          <IonTitle>{t('title')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <PageContentWithSidePanel
        sidePanel={
          !isError ? (
            <RequestsFilter onSubmit={handleSubmitFilter} appliedFilters={filters} />
          ) : null
        }
      >
        <IonContent className={`ion-padding ${shouldShowBlobs && 'withBlob'}`} ref={pageIonContent}>
          {getRequestsContent()}
          <Filters
            shouldDisplayFilters={shouldDisplayFilters}
            isFetchRequestsLoading={isLoading}
            filters={filters}
            filtersCount={filtersCount}
            handleSubmitFilter={handleSubmitFilter}
            filterModalRef={filterModal}
          />
        </IonContent>
      </PageContentWithSidePanel>
    </IonPage>
  );
};

export default Requests;
