import axios, { type AxiosInstance, AxiosRequestConfig } from 'axios';

import { API_BASE_URL } from '@shared/environments/environment';

type CustomAxiosRequestConfig = {
  validationErrorMessage?: string;
} & AxiosRequestConfig;

const httpService: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30_000,
});

httpService.defaults.headers.common = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
};

export { httpService, type CustomAxiosRequestConfig };
