const APP_CONFIG = {
  DEFAULT_LANGUAGE: 'fr',
  ENV: {
    LOCAL: 'local',
    DEV: 'dev',
    PROD: 'prod',
  },
  SUPPORTED_LANGUAGES: ['fr'],
  BREAKPOINTS: {
    XL: '(min-width: 1200px)',
  },
} as const;

const isProductionEnvironment = import.meta.env.VITE_ENV === APP_CONFIG.ENV.PROD;

export { APP_CONFIG, isProductionEnvironment };
