import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFormatDate } from '@carers/hooks/useFormatDate';
import useFormatTime from '@carers/hooks/useFormatTime';
import { DayScheduleRequest } from '@carers/models/RequestAndMission';
import Alert from '@shared/components/Alert/Alert';

type ConflictingDateProps = {
  daySchedules: DayScheduleRequest[];
};

const ConflictingDates = ({ daySchedules }: ConflictingDateProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers' });
  const { formatDate } = useFormatDate();
  const { formatTimeRange } = useFormatTime();

  const conflicts = daySchedules.flatMap((d) => d.conflictingDaySchedules);

  if (conflicts.length > 0) {
    return (
      <Alert
        type="warning"
        title={t('requests.detail.alerts.conflictDateTitle', {
          count: conflicts.length,
        })}
      >
        <ul className="ion-padding-start">
          {conflicts.map((conflict, index) => {
            const isLastItem = index === conflicts.length - 1;

            return (
              <li key={conflict.id} className={isLastItem ? '' : 'ion-margin-bottom'}>
                {formatDate(conflict.date)} (
                {formatTimeRange({
                  date: conflict.date,
                  startTimeInSeconds: conflict.startTime,
                  endTimeInSeconds: conflict.endTime,
                })}
                ) - {conflict.facilityName}
              </li>
            );
          })}
        </ul>
      </Alert>
    );
  }

  return null;
};

export default ConflictingDates;
