import { IonIcon, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { useFormatDate } from '@carers/hooks/useFormatDate';
import useDurationTime from '@carers/hooks/useFormatDurationTime';
import useFormatTime from '@carers/hooks/useFormatTime';
import { DayScheduleLight, DayScheduleRequest } from '@carers/models/RequestAndMission';
import NightIcon from '@shared/assets/icons/night.svg';
import { RYTHMS } from '@shared/constants/missionsConstants';

import styles from './MissionScheduleDates.module.css';

type MissionScheduleDateProps = {
  schedule: DayScheduleLight | DayScheduleRequest;
  shouldDateBeHighlighted?: boolean;
  shouldConflictBeHighlighted?: boolean;
};

const isScheduleWithConflictingDaySchedules = (
  daySchedule: DayScheduleLight | DayScheduleRequest,
): daySchedule is DayScheduleRequest => 'conflictingDaySchedules' in daySchedule;

const MissionScheduleDate = ({
  schedule,
  shouldDateBeHighlighted = false,
  shouldConflictBeHighlighted = false,
}: MissionScheduleDateProps) => {
  const { formatDate } = useFormatDate();

  const shouldHighlightConflict =
    isScheduleWithConflictingDaySchedules(schedule) &&
    schedule.conflictingDaySchedules.length > 0 &&
    shouldConflictBeHighlighted;

  return (
    <div
      className={`${shouldDateBeHighlighted ? styles.highlight : ''} ${
        shouldHighlightConflict ? styles.conflictingDay : ''
      }`}
    >
      {formatDate(schedule.date)}
    </div>
  );
};

type MissionScheduleTimeProps = {
  schedule: DayScheduleLight | DayScheduleRequest;
};

const MissionScheduleTime = ({ schedule }: MissionScheduleTimeProps) => {
  const { formatTimeRange } = useFormatTime();

  return (
    <div className={styles.timeSchedule}>
      {schedule.rythm.value === RYTHMS.NIGHT && <IonIcon icon={NightIcon} />}
      {formatTimeRange({
        date: schedule.date,
        startTimeInSeconds: schedule.startTime,
        endTimeInSeconds: schedule.endTime,
      })}
    </div>
  );
};

const MissionScheduleBreakDuration = ({ schedule }: MissionScheduleTimeProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.common.format' });
  const formatDuration = useDurationTime();

  if (schedule.breakDuration) {
    const { value, isPlural } = formatDuration(schedule.breakDuration);

    return (
      <IonLabel color="primary" className={styles.breakDuration}>
        {t('breakDuration', {
          breakDuration: value,
          count: isPlural ? 2 : 1,
        })}
      </IonLabel>
    );
  }

  return null;
};

export { MissionScheduleDate, MissionScheduleTime, MissionScheduleBreakDuration };
