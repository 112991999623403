import { IonCard } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { useSearchParamFilter } from '@carers/hooks/useSearchParamFilter';
import { ANALYTICS_LOG_EVENTS } from '@carers/utils/Analytics.ts';
import UserTaskCard from '@carers/views/Activity/components/UserTasks/UserTaskCard';
import UserTaskCardSkeleton from '@carers/views/Activity/components/UserTasks/UserTaskCardSkeleton';
import useGetUserTasksCount from '@carers/views/Activity/hooks/useGetUserTasksCount';
import { MISSIONS_FILTER_KEYS } from '@carers/views/Missions/models/MissionsFilters';
import BlankSlateError from '@shared/components/BlankSlate/BlankSlateError';
import { logEvent } from '@shared/utils/Analytics';

import pendingContractSignaturesIcon from '../../assets/images/pending-contract-signatures-icon.svg';
import pendingSchedulesValidationIcon from '../../assets/images/pending-schedules-validation-icon.svg';

import styles from './UserTasks.module.css';

const UserTasksList = () => {
  const { t } = useTranslation('app', { keyPrefix: 'carers' });
  const { data: tasks, isLoading, isError, refetch, error } = useGetUserTasksCount();
  const { applyFilters } = useSearchParamFilter('/carers/missions');

  const applyPendingContractSignaturesFilters = async () => {
    void logEvent(ANALYTICS_LOG_EVENTS.PENDING_CONTRACT_SIGNATURES_CARD_CLICK);
    applyFilters({
      [MISSIONS_FILTER_KEYS.TASKS]: ['CONTRACT_SIGNATURE'],
    });
  };

  const applyPendingSchedulesValidationsFilters = async () => {
    void logEvent(ANALYTICS_LOG_EVENTS.PENDING_SCHEDULES_VALIDATION_CARD_CLICK);
    applyFilters({
      [MISSIONS_FILTER_KEYS.TASKS]: ['SCHEDULES_VALIDATION'],
      [MISSIONS_FILTER_KEYS.IS_PAST]: 'true',
    });
  };

  const getUserTaskContent = () => {
    if (isLoading) {
      return (
        <div className="stack stack-horizontal">
          <UserTaskCardSkeleton />
          <UserTaskCardSkeleton />
        </div>
      );
    }

    if (isError) {
      return (
        <IonCard className={styles.cardTask}>
          <BlankSlateError error={error} action={refetch} />
        </IonCard>
      );
    }

    if (tasks) {
      return (
        <div className="stack stack-horizontal">
          <UserTaskCard
            value={tasks.pendingContractSignatureMissionsCount}
            label="carers.activity.tasks.contractSignature.pendingContractSignatureMissionsCount"
            icon={pendingContractSignaturesIcon}
            action={applyPendingContractSignaturesFilters}
          />
          <UserTaskCard
            value={tasks.pendingSchedulesValidationMissionsCount}
            label="carers.activity.tasks.schedulesValidation.pendingSchedulesValidationMissionsCount"
            icon={pendingSchedulesValidationIcon}
            action={applyPendingSchedulesValidationsFilters}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div className={styles.container}>
      <h5 className={`${styles.title}`}>{t('activity.myTasks')}</h5>
      <div className="stack stack-horizontal stack-no-space">{getUserTaskContent()}</div>
    </div>
  );
};

export default UserTasksList;
