import { useTranslation } from 'react-i18next';

type DateFormatConfig = 'MEDIUM_DATE' | 'MEDIUM_DATE_NO_YEAR' | 'FULL_DATE_WITH_TIME' | 'FULL_DATE';

const DATE_CONFIG: { [key in DateFormatConfig]: Intl.DateTimeFormatOptions } = {
  // mar. 02 janv. 2024
  MEDIUM_DATE: {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
  // mar. 2 janv.
  MEDIUM_DATE_NO_YEAR: {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
  },
  FULL_DATE: {
    dateStyle: 'full',
  },
  FULL_DATE_WITH_TIME: {
    dateStyle: 'full',
    timeStyle: 'short',
  },
};

const useFormatDate = (locale?: string) => {
  const { i18n } = useTranslation('app', { keyPrefix: 'carers' });
  const language = locale ?? i18n.language;

  const formatDate = (
    dateString: string,
    config: Intl.DateTimeFormatOptions = DATE_CONFIG.MEDIUM_DATE,
  ): string => {
    try {
      const d = new Date(dateString);
      const formatter = Intl.DateTimeFormat(language, config);

      return formatter.format(d).replace(/^\w/, (c) => c.toUpperCase());
    } catch (error) {
      return '';
    }
  };

  return {
    formatDate,
  };
};

export { useFormatDate, DATE_CONFIG };
