import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import RoundWaveContentHeader from '@carers/components/RoundWaveContentHeader/RoundWaveContentHeader';
import CalendarMissionsByMonth from '@carers/views/Activity/components/MissionsByMonth/CalendarMissionsByMonth';
import UserTasksList from '@carers/views/Activity/components/UserTasks/UserTasksList';
import LogoMediflashLinear from '@shared/assets/images/logo-mediflash-linear.svg';

const Activity = () => {
  const { t } = useTranslation('app');

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="ion-hide-xl-up ion-text-center">
            <img src={LogoMediflashLinear} width="150" height="26" alt={t('shared.appName')} />
          </IonTitle>
          <IonTitle className="ion-hide-xl-down">{t('carers.activity.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="withBlob ion-padding">
        <RoundWaveContentHeader />
        <div className="stack">
          <UserTasksList />
          <CalendarMissionsByMonth />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Activity;
