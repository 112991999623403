import type {} from '@batch.com/cordova-plugin';
import { Capacitor } from '@capacitor/core';

import getUserId from '@carers/views/Auth/OTPCode/utils/auth';
import { isProductionEnvironment } from '@shared/constants/appConstants';

let isBatchInitialized = false;

// # Native setup

const initBatchNative = () => {
  document.addEventListener(
    'deviceready',
    () => {
      batch.setConfig({
        androidAPIKey: import.meta.env.VITE_BATCH_ANDROID_API_KEY,
        iOSAPIKey: import.meta.env.VITE_BATCH_IOS_API_KEY,
      });
      batch.start();
      batch.push.setiOSShowForegroundNotifications(true);
      batch.push.requestNotificationAuthorization();
    },
    false,
  );
};

// # Web setup
// Batch web setup documentation : https://doc.batch.com/web/getting-started/javascript-snippet/

interface Profile {
  identify: (data: { customId: string | null }) => void;
}
interface Api {
  profile: () => Promise<Profile>;
}

interface BatchSDK {
  (...args: any[]): void; // eslint-disable-line @typescript-eslint/no-explicit-any
  (callback: (api: Api) => void): void;
  q?: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
}

declare global {
  interface Window {
    batchSDK: BatchSDK;
  }
}
type WebNotificationModalContent = {
  text: string;
  positiveSubBtnLabel: string;
  negativeBtnLabel: string;
};
const BATCH_WEB_SCRIPT_URL = 'https://via.batch.com/v4/bootstrap.min.js';
const BATCH_SCRIPT_ID = 'batchSDK';

const MEDIFLASH_SECOND_LEVEL_DOMAIN = 'mediflash';

type BatchSDKUIConfig = {
  native?: object;
  alert?: {
    text: string;
    positiveSubBtnLabel: string;
    negativeBtnLabel: string;
    positiveBtnStyle: {
      shadow: boolean; // eslint-disable-line @typescript-eslint/naming-convention
      backgroundColor: string;
      hoverBackgroundColor: string;
      textColor: string;
    };
  };
};

const getBatchScript = () => {
  const script = document.createElement('script');
  script.async = true;
  script.src = BATCH_WEB_SCRIPT_URL;

  return script;
};

const insertScript = (script: HTMLScriptElement) => {
  const firstScript = document.getElementsByTagName('script')[0];

  if (!!firstScript) {
    firstScript.parentNode?.insertBefore(script, firstScript);

    return;
  }

  document.head.appendChild(script);
};

const getAlertContent = (webNotificationModalContent: WebNotificationModalContent) => {
  const positiveBtnStyle = {
    shadow: true,
    backgroundColor: '#00214D',
    hoverBackgroundColor: '#001D45',
    textColor: 'white',
  };

  return { ...webNotificationModalContent, positiveBtnStyle };
};

const initBatchWeb = (webNotificationModalContent: WebNotificationModalContent) => {
  window[BATCH_SCRIPT_ID] =
    window[BATCH_SCRIPT_ID] ||
    function (...args) {
      (window[BATCH_SCRIPT_ID].q = window[BATCH_SCRIPT_ID].q || []).push(args);
    };

  const script = getBatchScript();
  insertScript(script);

  let batchSDKUIConfig: BatchSDKUIConfig = {
    native: {},
  };

  if (
    navigator.userAgent.indexOf('Firefox') !== -1 ||
    (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1)
  ) {
    batchSDKUIConfig = {
      alert: getAlertContent(webNotificationModalContent),
    };
  }

  window[BATCH_SCRIPT_ID]('setup', {
    apiKey: import.meta.env.VITE_BATCH_WEB_API_KEY,
    subdomain: MEDIFLASH_SECOND_LEVEL_DOMAIN,
    authKey: import.meta.env.VITE_BATCH_WEB_AUTH_KEY,
    dev: !isProductionEnvironment,
    vapidPublicKey: import.meta.env.VITE_BATCH_VAPID_PUBLIC_KEY,
    ui: batchSDKUIConfig,
  });
};

const setWebCustomUserId = (userId: string | null) => {
  window[BATCH_SCRIPT_ID]((api: Api) => {
    void api.profile().then((profile: Profile) => {
      profile.identify({ customId: userId });
    });
  });
};

const setNativeCustomUserId = (userId: string | null) => {
  batch.user.getEditor().setIdentifier(userId).save();
};

const setCustomUserId = (userId: string | null) => {
  const platform = Capacitor.getPlatform();
  const isWebPlatform = platform === 'web';

  if (!!isWebPlatform) {
    setWebCustomUserId(userId);

    return;
  }

  setNativeCustomUserId(userId);
};

const init = (webNotificationModalContent: WebNotificationModalContent, accessToken?: string) => {
  const platform = Capacitor.getPlatform();
  const isWebPlatform = platform === 'web';

  if (!!isBatchInitialized) {
    return;
  }

  if (!!isWebPlatform) {
    initBatchWeb(webNotificationModalContent);
  } else {
    initBatchNative();
  }

  isBatchInitialized = true;

  const userId = !!accessToken ? getUserId(accessToken) : null;
  setCustomUserId(userId);
};

export { init, setCustomUserId };
