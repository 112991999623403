import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import AdditionalInformationCard from '@carers/components/AdditionalInformationCard/AdditionalInformationCard';
import CardDetail from '@carers/components/CardDetail/CardDetail';
import { isModalClosedIntentionally, Modal } from '@carers/components/Modal';
import PageContentWithSidePanel from '@carers/components/PageContentWithSidePanel/PageContentWithSidePanel';
import PaymentCard from '@carers/components/PaymentCard/PaymentCard';
import RoundWaveContentHeader from '@carers/components/RoundWaveContentHeader/RoundWaveContentHeader';
import useGetMissionDetail from '@carers/views/Missions/hooks/useGetMissionDetail';
import useSignContract from '@carers/views/Missions/hooks/useSignContract';
import ActionsPanel from '@carers/views/Missions/MissionDetail/components/ActionsPanel/ActionsPanel';
import MissionContractCard from '@carers/views/Missions/MissionDetail/components/Cards/MissionContractCard';
import MissionFacilityCard from '@carers/views/Missions/MissionDetail/components/Cards/MissionFacilityCard';
import MissionPaymentCard from '@carers/views/Missions/MissionDetail/components/Cards/MissionPaymentCard';
import MissionScheduleCard from '@carers/views/Missions/MissionDetail/components/Cards/MissionScheduleCard';
import MobileFooter from '@carers/views/Missions/MissionDetail/components/MobileFooter/MobileFooter';
import ReportSchedulesProblem from '@carers/views/Missions/MissionDetail/components/ReportSchedulesProblem/ReportSchedulesProblem';
import ValidateSchedules from '@carers/views/Missions/MissionDetail/components/ValidateSchedules/ValidateSchedules';
import { Mission } from '@carers/views/Missions/models/Missions';
import MissionStatus from '@carers/views/Missions/models/MissionStatus';
import { wereSchedulesAlreadyValidated as wereSchedulesAlreadyValidatedError } from '@carers/views/Missions/utils/missionErrors';
import { doesMissionNeedActions } from '@carers/views/Missions/utils/missionHelpers';
import RequestDetailSkeleton from '@carers/views/Requests/RequestDetail/RequestDetailSkeleton/RequestDetailSkeleton';
import BadgeIcon2 from '@shared/assets/icons/badge-2.svg';
import BusinessIcon from '@shared/assets/icons/business.svg';
import PhoneIcon from '@shared/assets/icons/local_phone.svg';
import Alert from '@shared/components/Alert/Alert';
import BlankSlateError from '@shared/components/BlankSlate/BlankSlateError';
import { SERVICES } from '@shared/constants/missionsConstants';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';

import styles from './MissionDetail.module.css';

const shouldShowButtons = (mission?: Mission) => {
  if (!mission) {
    return { shouldShowActions: false, shouldShowValidateSchedulesButton: false };
  }

  const shouldShowActions = doesMissionNeedActions(mission);
  const shouldShowValidateSchedulesButton =
    mission.status.value === MissionStatus.MISSION_SCHEDULES_AWAITING_VALIDATION &&
    !mission.schedulesProblemReportedAt &&
    !mission.schedulesValidatedAt;

  return { shouldShowActions, shouldShowValidateSchedulesButton };
};

const MissionDetail = () => {
  const { t } = useTranslation('app', { keyPrefix: 'carers' });
  const isScreenXL = useIsScreenXL();
  const { id: missionId } = useParams<{ id: string }>();
  const { data: mission, refetch, error, isError, isLoading } = useGetMissionDetail(missionId);
  const { startSigningSequence } = useSignContract(mission?.contractSignatureUrl);
  const router = useIonRouter();

  const [shouldShowReportScheduleProblem, setShouldShowReportScheduleProblem] = useState(false);
  const [shouldShowValidateSchedules, setShouldShowValidateSchedules] = useState(false);

  const { shouldShowActions, shouldShowValidateSchedulesButton } = shouldShowButtons(mission);

  const shouldShowReportSchedulesButton =
    !mission?.schedulesProblemReportedAt && !mission?.contractSignatureUrl;

  const shouldShowAdditionalInformationCard =
    !!mission?.service || !!mission?.additionalInformation;

  const shouldShowMobileFooter = !isScreenXL && shouldShowActions;

  const isContractNotSigned = !!mission?.contractSignatureUrl;
  const shouldDisplayContractCard = !!mission?.contractSignedAt || !!mission?.contractSignatureUrl;
  const shouldDisplayPaymentCard = !!mission?.paymentDueOn || !!mission?.paymentNotificationSentAt;

  const reportModal = useRef<HTMLIonModalElement>(null);
  const validateModal = useRef<HTMLIonModalElement>(null);

  const dismissReportSchedulesProblemModal = async () => {
    await reportModal.current?.dismiss();
    setShouldShowReportScheduleProblem(false);
  };

  const dismissValidateSchedulesModal = async () => {
    await validateModal.current?.dismiss();
  };

  const onWillDismissModal = (event: CustomEvent<OverlayEventDetail<void>>) => {
    const { role } = event.detail;

    if (
      isModalClosedIntentionally(role) &&
      isError &&
      wereSchedulesAlreadyValidatedError(error.response?.data.error?.code)
    ) {
      router.push('/carers/missions', 'back');
    }
  };

  const isAtHomeMission = mission?.service.value === SERVICES.HOME_NURSING_SERVICES;

  const getSidePanel = () => {
    if (shouldShowActions) {
      return (
        <ActionsPanel
          dismissValidateModal={dismissValidateSchedulesModal}
          dismissReportModal={dismissReportSchedulesProblemModal}
          mission={mission}
          onStartSigningSequence={startSigningSequence}
          setShowReportScheduleProblem={setShouldShowReportScheduleProblem}
          setShowValidateSchedules={setShouldShowValidateSchedules}
          shouldShowValidateSchedulesButton={shouldShowValidateSchedulesButton}
          shouldShowReportSchedulesButton={shouldShowReportSchedulesButton}
          shouldShowValidateSchedules={shouldShowValidateSchedules}
          shouldShowReportScheduleProblem={shouldShowReportScheduleProblem}
        />
      );
    }

    return null;
  };

  // eslint-disable-next-line complexity
  const getContentMissionDetail = () => {
    if (isError) {
      return (
        <IonContent>
          <BlankSlateError error={error} action={refetch} />
        </IonContent>
      );
    }

    if (isLoading) {
      return <RequestDetailSkeleton />;
    }

    if (mission) {
      return (
        <PageContentWithSidePanel sidePanel={getSidePanel()}>
          <IonContent className="ion-padding withBlob">
            <RoundWaveContentHeader />
            <div className="stack">
              <MissionFacilityCard mission={mission} />

              <div className={`stack ${isScreenXL && 'stack-horizontal'}`}>
                <div className={`stack ${!isScreenXL && 'stack-horizontal'}`}>
                  <CardDetail icon={BadgeIcon2} title={mission.job.label} />
                  <CardDetail icon={BusinessIcon} title={mission.service.label} />
                </div>
                <div className="stack">
                  {isAtHomeMission && (
                    <Alert type="warning" title={t('requests.detail.alerts.atHomeMission.title')}>
                      <IonLabel>{t('requests.detail.alerts.atHomeMission.message')}</IonLabel>
                    </Alert>
                  )}
                  <PaymentCard mission={mission} />
                </div>
              </div>

              <MissionScheduleCard mission={mission} />

              {shouldShowAdditionalInformationCard && (
                <AdditionalInformationCard request={mission} />
              )}

              {shouldDisplayContractCard && <MissionContractCard mission={mission} />}
              {shouldDisplayPaymentCard && <MissionPaymentCard mission={mission} />}

              <div className="ion-text-center ion-text-xl-end">
                <IonButton
                  fill="clear"
                  href={`tel:${mission.lead.phone}`}
                  className="ion-text-wrap"
                >
                  <IonIcon slot="start" aria-hidden="true" icon={PhoneIcon} />
                  {t('missions.detail.actions.contactFromMediflash', {
                    person: mission.lead.firstName,
                  })}
                </IonButton>
              </div>
            </div>
          </IonContent>
        </PageContentWithSidePanel>
      );
    }

    return null;
  };

  const getMobileFooter = () => {
    if (!mission) {
      return null;
    }

    if (!shouldShowMobileFooter) {
      return null;
    }

    return (
      <>
        <MobileFooter
          shouldShowSignContractButton={isContractNotSigned}
          startSigningSequence={startSigningSequence}
          shouldShowValidateSchedulesButton={shouldShowValidateSchedulesButton}
          shouldShowReportSchedulesButton={shouldShowReportSchedulesButton}
        />
        {shouldShowReportSchedulesButton && (
          <Modal
            ref={reportModal}
            trigger="open-report-modal"
            onWillDismiss={onWillDismissModal}
            breakpoints={[0, 1]}
            initialBreakpoint={1}
            aria-labelledby="report-modal-title"
          >
            <ReportSchedulesProblem
              dismissModal={dismissReportSchedulesProblemModal}
              mission={mission}
              setShowReportSchedulesProblem={setShouldShowReportScheduleProblem}
            />
          </Modal>
        )}

        {shouldShowValidateSchedulesButton && (
          <Modal
            ref={validateModal}
            trigger="open-validate-modal"
            onWillDismiss={onWillDismissModal}
            breakpoints={[0, 1]}
            initialBreakpoint={1}
            aria-labelledby="validate-modal-title"
          >
            <ValidateSchedules
              mission={mission}
              dismissModal={dismissValidateSchedulesModal}
              setShowValidateSchedules={setShouldShowValidateSchedules}
            />
          </Modal>
        )}
      </>
    );
  };

  return (
    <IonPage className={styles.multipleContent}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/carers/missions" />
          </IonButtons>
          <IonTitle>{t('missions.detail.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      {getContentMissionDetail()}

      {getMobileFooter()}
    </IonPage>
  );
};

export default MissionDetail;
