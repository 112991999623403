import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

type TimeFormatProps = {
  date: string;
  startTimeInSeconds: number;
  endTimeInSeconds: number;
  formatOptions?: Intl.DateTimeFormatOptions;
};

const DEFAULT_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  timeStyle: 'short',
};

const useFormatTime = (locale?: string) => {
  const { i18n } = useTranslation('app', { keyPrefix: 'carers' });
  const language = locale ?? i18n.language;

  const formatTimeRange = ({
    date,
    startTimeInSeconds,
    endTimeInSeconds,
    formatOptions = DEFAULT_FORMAT_OPTIONS,
  }: TimeFormatProps): string => {
    const startDateTime = dayjs(date).add(startTimeInSeconds, 'seconds');
    const endDateTime = dayjs(date).add(endTimeInSeconds, 'seconds');

    if (endTimeInSeconds < startTimeInSeconds) {
      endDateTime.add(1, 'days');
    }

    const formatter = Intl.DateTimeFormat(language, formatOptions);

    return `${formatter.format(startDateTime.toDate())} - ${formatter.format(
      endDateTime.toDate(),
    )}`;
  };

  return { formatTimeRange };
};

export default useFormatTime;
