import { Capacitor } from '@capacitor/core';
import { IonApp, setupIonicReact } from '@ionic/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import './global.css';

import AppErrorFallback from '@carers/components/AppErrorFallback/AppErrorFallback';
import EnvironmentBanner from '@carers/components/EnvironmentBanner/EnvironmentBanner';
import useSetUpPlatform from '@carers/hooks/useSetUpPlatform';
import BackButtonIcon from '@shared/assets/icons/chevron-back-sharp.svg';
import BlankSlate from '@shared/components/BlankSlate/BlankSlate';
import BatchSDK from '@shared/components/Notifications/BatchSDK';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';
import useUsercentrics from '@shared/hooks/useUsercentrics';
import '@shared/i18n';
import queryClient from '@shared/queryClient';
import AppRouterOutlet from '@shared/routes/AppRouterOutlet';
import { init as firebaseAnalyticsInit } from '@shared/utils/Analytics';

setupIonicReact({ mode: 'md', swipeBackEnabled: false, backButtonIcon: BackButtonIcon });

const App = () => {
  const isScreenXL = useIsScreenXL();
  useSetUpPlatform();
  const { consent } = useUsercentrics();

  useEffect(() => {
    if (consent === 'ALL_ACCEPTED') {
      const platform = Capacitor.getPlatform();
      const isWebPlatform = platform === 'web';

      if (isWebPlatform) {
        firebaseAnalyticsInit().catch((error) => {
          throw error;
        });
      }
    }
  }, [consent]);

  const hasCarerGivenConsent = consent === 'ALL_ACCEPTED';

  return (
    <QueryClientProvider client={queryClient}>
      {hasCarerGivenConsent && <BatchSDK />}
      <IonApp className={isScreenXL ? 'screenXL' : ''}>
        <ErrorBoundary FallbackComponent={AppErrorFallback}>
          <Suspense fallback={<BlankSlate />}>
            <AppRouterOutlet />
          </Suspense>
        </ErrorBoundary>
      </IonApp>
      <ReactQueryDevtools initialIsOpen={false} position="bottom" buttonPosition="top-right" />
      <EnvironmentBanner position="top-right" />
    </QueryClientProvider>
  );
};

export default App;
