import { Capacitor } from '@capacitor/core';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from 'react';
import { Redirect, Route } from 'react-router';

import AppErrorFallback from '@carers/components/AppErrorFallback/AppErrorFallback';
import AppUrlOpenListener from '@carers/components/AppUrlOpenListener/AppUrlOpenListener';
import DocumentTitle from '@carers/components/DocumentTitle/DocumentTitle';
import Login from '@carers/views/Auth/Login/Login';
import OTPCode from '@carers/views/Auth/OTPCode/OTPCode';
import CarersTabsOutlet from '@carers/views/Tabs/TabsOutlet';
import FacilitiesTabsOutlet from '@facilities/views/Tabs/TabsOutlet';
import HttpInterceptors from '@shared/HttpInterceptors/HTTPInterceptors';
import { DEFAULT_MAIN_ROUTE } from '@shared/routes/appRoutes';
import AuthProtectedRoute from '@shared/routes/AuthProtectedRoute';
import ROOT_ROUTES from '@shared/routes/rootRoutes';
import { RootRouteId } from '@shared/routes/route';

const rootRoutesComponents: Record<RootRouteId, React.FC> = {
  login: Login,
  otpCode: OTPCode,
};

const AppRouterOutlet = () => {
  const platform = Capacitor.getPlatform();
  const isWebPlatform = platform === 'web';

  const tabsRouters = [
    <AuthProtectedRoute key="carersRouter" path="/carers" render={() => <CarersTabsOutlet />} />,
  ];

  if (isWebPlatform) {
    tabsRouters.push(
      <Route
        key="facilitiesRouter"
        path="/facilities/:facilityId"
        component={FacilitiesTabsOutlet}
      />,
    );
  }

  return (
    <IonReactRouter>
      <DocumentTitle />
      <HttpInterceptors />
      <AppUrlOpenListener />
      {/* CHECK https://github.com/ionic-team/ionic-framework/issues/24177 */}
      <IonRouterOutlet id="main" animated={false}>
        {ROOT_ROUTES.map((route) => (
          <Route
            exact
            path={route.path}
            component={rootRoutesComponents[route.id]}
            key={route.id}
          />
        ))}

        {tabsRouters.map((tabRouter) => tabRouter)}
        <Redirect exact from="/" to={DEFAULT_MAIN_ROUTE} />
        <Route render={() => <AppErrorFallback />} />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

export default AppRouterOutlet;
